var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading.fullscreen.lock",
          value: _vm.fullscreenLoading,
          expression: "fullscreenLoading",
          modifiers: { fullscreen: true, lock: true }
        }
      ],
      staticStyle: { padding: "16px" }
    },
    [
      _c("h3", { staticStyle: { "margin-bottom": "50px" } }, [
        _vm._v(_vm._s(_vm.detail.ProcessTitle))
      ]),
      _c("h4", [_vm._v("工单信息")]),
      _c("div", { staticClass: "fielList" }, [
        _c("p", [
          _c("span", [_vm._v("工单号：")]),
          _vm._v(_vm._s(_vm.detail.TicketId || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("园区：")]),
          _vm._v(_vm._s(_vm.detail.Campus || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("机房：")]),
          _vm._v(_vm._s(_vm.detail.IdcpName || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("设备数量：")]),
          _vm._v(_vm._s(_vm.detail.Amount || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("场景：")]),
          _vm._v(_vm._s(_vm.detail.Scene || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("超时时间：")]),
          _vm._v(_vm._s(_vm.detail.OlaTime || "--"))
        ]),
        _c("p", [
          _c("span", [_vm._v("结单时间：")]),
          _vm._v(_vm._s(_vm.detail.EndTime || "--"))
        ])
      ]),
      _c("h4", { staticStyle: { "margin-top": "20px" } }, [_vm._v("流程详情")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "1200px" },
          attrs: { border: "", data: _vm.detail.TaskList }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "Type", label: "流程节点" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _vm.detail.Scene == "搬入"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.Type == "Follow"
                                ? "随工"
                                : scope.row.Type == "Outin"
                                ? "到货验收"
                                : scope.row.Type == "Updown"
                                ? "上架"
                                : scope.row.Type == "Line"
                                ? "接线"
                                : ""
                            )
                          )
                        ])
                      : _vm._e(),
                    _vm.detail.Scene == "搬出"
                      ? _c("span", [
                          _vm._v(
                            _vm._s(
                              scope.row.Type == "Follow"
                                ? "随工"
                                : scope.row.Type == "Outin"
                                ? "出库"
                                : scope.row.Type == "Updown"
                                ? "下架"
                                : scope.row.Type == "Line"
                                ? "拔线"
                                : ""
                            )
                          )
                        ])
                      : _vm._e()
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "BeginTime", label: "开始时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "TaskEndTime", label: "截止时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "EndTime", label: "完工时间" }
          }),
          _c("el-table-column", {
            attrs: { prop: "TimeoutStatus", label: "是否超时" }
          }),
          _c("el-table-column", {
            attrs: { prop: "Operator", label: "处理人" }
          })
        ],
        1
      ),
      _c("h4", { staticStyle: { "margin-top": "20px" } }, [_vm._v("设备详情")]),
      _c(
        "el-table",
        {
          staticStyle: { width: "1200px" },
          attrs: { border: "", data: _vm.detail.DetailList }
        },
        [
          _c("el-table-column", {
            attrs: { prop: "AssetId", label: "固资号" }
          }),
          _c("el-table-column", {
            attrs: { prop: "IdcpName", label: "源机房" }
          }),
          _c("el-table-column", {
            attrs: { prop: "OppositeIdcpName", label: "目的机房" }
          }),
          _c("el-table-column", {
            attrs: { prop: "IsAiServer", label: "是否AI服务器" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [
                      _vm._v(_vm._s(scope.row.IsAiServer == 1 ? "是" : "否"))
                    ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { prop: "LogisticsNum", label: "物流运单号" }
          }),
          _c("el-table-column", { attrs: { prop: "Model", label: "机型" } }),
          _c("el-table-column", {
            attrs: { prop: "ServerHeight", label: "设备高度" }
          }),
          _c("el-table-column", {
            attrs: { prop: "TaskStatus", label: "任务状态" }
          }),
          _c("el-table-column", {
            attrs: { prop: "RejectReason", label: "驳回原因" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }