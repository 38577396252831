<template>
  <div style="padding:16px" v-loading.fullscreen.lock="fullscreenLoading">
    <h3 style="margin-bottom:50px">{{ detail.ProcessTitle }}</h3>
    <h4>工单信息</h4>
    <div class="fielList">
      <p><span>工单号：</span>{{ detail.TicketId || '--' }}</p>
      <p><span>园区：</span>{{ detail.Campus || '--' }}</p>
      <p><span>机房：</span>{{ detail.IdcpName || '--' }}</p>
      <p><span>设备数量：</span>{{ detail.Amount || '--' }}</p>
      <p><span>场景：</span>{{ detail.Scene || '--' }}</p>
      <p><span>超时时间：</span>{{ detail.OlaTime || '--' }}</p>
      <p><span>结单时间：</span>{{ detail.EndTime || '--' }}</p>
    </div>
    <h4 style="margin-top:20px">流程详情</h4>
    <el-table border :data="detail.TaskList" style="width: 1200px">
      <el-table-column prop="Type" label="流程节点">
        <template slot-scope="scope">
          <span v-if="detail.Scene == '搬入'">{{
            scope.row.Type == 'Follow'
              ? '随工'
              : scope.row.Type == 'Outin'
              ? '到货验收'
              : scope.row.Type == 'Updown'
              ? '上架'
              : scope.row.Type == 'Line'
              ? '接线'
              : ''
          }}</span>
          <span v-if="detail.Scene == '搬出'">{{
            scope.row.Type == 'Follow'
              ? '随工'
              : scope.row.Type == 'Outin'
              ? '出库'
              : scope.row.Type == 'Updown'
              ? '下架'
              : scope.row.Type == 'Line'
              ? '拔线'
              : ''
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="BeginTime" label="开始时间"> </el-table-column>
      <el-table-column prop="TaskEndTime" label="截止时间"> </el-table-column>
      <el-table-column prop="EndTime" label="完工时间"> </el-table-column>
      <el-table-column prop="TimeoutStatus" label="是否超时"> </el-table-column>
      <el-table-column prop="Operator" label="处理人"> </el-table-column>
    </el-table>
    <h4 style="margin-top:20px">设备详情</h4>
    <el-table border :data="detail.DetailList" style="width: 1200px">
      <el-table-column prop="AssetId" label="固资号"> </el-table-column>
      <el-table-column prop="IdcpName" label="源机房"> </el-table-column>
      <el-table-column prop="OppositeIdcpName" label="目的机房"> </el-table-column>
      <el-table-column prop="IsAiServer" label="是否AI服务器">
        <template slot-scope="scope">
          <span>{{ scope.row.IsAiServer == 1 ? '是' : '否' }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="LogisticsNum" label="物流运单号"> </el-table-column>
      <el-table-column prop="Model" label="机型"> </el-table-column>
      <el-table-column prop="ServerHeight" label="设备高度"> </el-table-column>
      <el-table-column prop="TaskStatus" label="任务状态"> </el-table-column>
      <el-table-column prop="RejectReason" label="驳回原因"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { moveDetail } from '@/api/monitor';
export default {
  name: 'moveDetail',
  data() {
    return {
      fullscreenLoading: false,
      detail: {},
    };
  },
  created() {
    this.moveDetail();
  },
  methods: {
    //详情接口
    moveDetail() {
      this.fullscreenLoading = true;
      moveDetail({ticketId:this.$route.query.ticketId})
        .then((res) => {
          this.detail = res.data;
          this.fullscreenLoading = false;
        })
        .catch(() => {
          this.fullscreenLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.fielList {
  display: flex;
  flex-wrap: wrap;
  p {
    font-size: 14px;
    line-height: 18px;
    margin: 0 80px 10px 0;
    padding: 0 !important;
    color: #333;
    span {
      color: #666;
    }
  }
}
</style>
